<template>
  <div
    class="p-1 px-2 cursor-pointer rounded-md flex items-center transition-colors"
    :class="classes"
  >
    <fa-icon v-if="hasIcon" :icon="icon"></fa-icon>
    <div class="pl-1">
        <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    icon: String,
    backgroundColor: {
      type: String,
      default: "bg-gray-400 hover:bg-gray-300",
    },
    textColor:{
        type:String,
        default: 'text-white'
    },
    textSize:{
      type:String,
      default:'text-xs'
    },
    showBorder:{
        type:Boolean,
        default:false,
    },
    borderStyle:{
        type:String,
        default:"border border-white"
    }
  },
  computed: {
    borderClass(){
        return this.showBorder ? this.borderStyle : '';
    },
    classes() {
      return `${this.backgroundColor} ${this.borderClass} ${this.textColor} ${this.textSize}`;
    },
    hasIcon() {
      return this.icon != null;
    },
  },
});
</script>

<style>
</style>