import axios from "axios";
import store from "@/store";


//axios.defaults.baseURL = process.API_URL;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;


export function authInterceptor() {
  axios.interceptors.request.use((request) => {    
    const authToken = store.getters.getAuthToken;
    if (authToken) {
      request.headers.common.Authorization = `Bearer ${authToken}`;
    }
    return request;
  });
}