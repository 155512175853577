<template>
  <div class="flex justify-center items-center cursor-pointer" @click="clickHandler">
    <h2 v-if="label" class="pr-2 text-gray-300"
        :class="{'text-green-300' : !value}"
    >Hide Assigned </h2>
    <div class="w-12 h-6 flex items-center  transform rounded-full p-1 duration-300 ease-in-out" :class="{ 'bg-green-400': value, 'bg-gray-300': !value}">
      <div class="bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out" :class="{ 'translate-x-5': value,}"></div>
    </div>
  </div>
</template>


<script>
const colorRegExp = /(#(?:[0-9a-f]{2}){2,4}|(#[0-9a-f]{3})|(rgb|hsl)a?\((-?\d+%?[,\s]+){2,3}\s*[\d.]+%?\))/i;
import {defineComponent} from 'vue';
export default defineComponent({
  name: "switch-box",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label:{
      type: String
    },
    color: {
      type: String,
      default: "#5d9cec",
      validator: (color) => colorRegExp.test(color),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler() {
      console.log('clicked');
      this.$emit('update:value', !this.value);
    },
  },
  computed: {
    checkedBg() {
      return this.value
        ? `background-color: ${this.color}; border-color: ${this.color};`
        : "";
    },
  },
});
</script>
<style></style>