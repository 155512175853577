import { getCourseDetails } from "../../data/courses.data";

const state = {
    course: null,
}

const getters = {
    getSelectedCourse(state) {
        console.log('state', state.course)
        return state.course;
    }
}

const mutations = {
    SET_COURSE(state, { course }) {
        state.course = course;
    }
}

const actions = {
    setSelectedCourse({ commit }, payload = {
        course: {},
        onSuccess: null,
    }) {
        if (payload && payload.course) {
            commit('SET_COURSE', {
                course: payload.course
            });
            if(payload.onSuccess) payload.onSuccess(true)
        }
    },
    async fetchCourseBySlug({ commit }, payload = {
        slug: '',
        onSuccess: null,
        onErrror: null,
        }) {
        try {
            const response = await getCourseDetails(payload.slug)
            // console.log('respon', response[1]['course'][0])
            if(response && response[1] && response[1]['course'] && response[1]['course'][0]) {
                const course = response[1]['course'][0];
                if(course) {
                    course['show_objective'] = true;
                    course['show_outcome'] = true;
                    course['show_description'] = true;
                }

                commit('SET_COURSE', {
                    course: course
                });
            } else {
                commit('SET_COURSE', {})
            }

            if(payload.onSuccess) payload.onSuccess(response.data.data)
        } catch (error) {
            console.log(error.response);
            commit('SET_COURSE', {})
            if(payload.onErrror) payload.onErrror(error.response)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}