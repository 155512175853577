<template>
  <div class="px-3 py-1 rounded-sm cursor-pointer" :class="getStyles">
    <slot></slot>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    showBorder: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      default: "border-2 border-white/20",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    backgroundColor: {
      type: String,
      default: "bg-sky-400 hover:bg-sky-500",
    },
  },

  computed: {
    bgStyle() {
      if (this.disabled) {
        return "bg-neutral-300 text-white/75";
      }
      return this.backgroundColor;
    },
    borderStyle() {
      return this.showBorder ? this.border : "";
    },
    getStyles() {
      return `${this.borderStyle} ${this.textColor} ${this.bgStyle}`;
    },
  },
});
</script>

<style></style>
