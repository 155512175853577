//export const baseURL = 'https://tgw.pandaisuite.net/api/v1/';
/* export const baseURL = 'https://pandaisuite.local/api/v1/';

export const authUri = 'http://pandaisuite.local/oauth/authorize_access?target=http://localhost:8081/pandai_auth/'; */

//const apiVersion = 'v1';
//const apiBaseURL = 'https://app.pandaisuite.com/';
//const baseURL = 'http://192.168.143.22:8080/'

const baseURL = process.env.VUE_APP_BASE_URL;
const apiBaseURL = process.env.VUE_APP_API_URL;
const apiVersion = process.env.VUE_APP_API_VERSION;


export const apiURL = `${apiBaseURL}api/${apiVersion}/`;
export const authUri = `${apiBaseURL}oauth/authorize_access?target=${baseURL}pandai_auth/`;