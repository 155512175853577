<!-- @format -->

<template>
  <slideout @closing="onClosing" v-model="onShow" title="Course Registration" size="0">
    <div class="p-5">
      <div>
        <div class="p-2 rounded border bg-white flex" v-if="courserun != null">
          <div class="w-1/4">
            <img :src="courserun.course.image_url" class="object-cover h-40 w-full" />
          </div>
          <div class="w-3/4">
            <div class="flex mx-2 text-xl text-sky-400 font-semibold px-4 pb-4 border-b">
              <div>
                <fa-icon icon="book-open"></fa-icon>
              </div>
              <div class="text-indigo-700 px-4">
                {{ courserun.course.name }}
                <span class="text-lg text-rose-500">Batch#{{ courserun.batch_number }}</span>
              </div>
            </div>

            <div class="flex w-full py-4">
              <div class="w-1/2">
                <CardListTile title="Reference Number">
                  {{ courserun.course.reference_number }}
                </CardListTile>
                <CardListTile title="Description">
                  {{ courserun.course.description }}
                </CardListTile>
              </div>

              <div class="w-1/2">
                <CardListTile title="Objective">
                  {{ courserun.course.objective }}
                </CardListTile>
                <CardListTile title="Course Date">
                  {{ courserun.course_start }} - {{ courserun.course_end }}
                </CardListTile>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 my-8" v-if="courserun">
        <div class="w-full flex flex-col" v-for="field in courserun.custom_fields" :key="field">
          <div v-if="field.input_type !== 'switch'">
            <div v-if="field.is_mandatory == 1">
                <label for="name" class="block text-indigo-900">{{ field.label }} <span class="text-red-600">*</span></label>
              </div>
              <div v-else>
                <label for="name" class="block text-indigo-900">{{ field.label }}</label>
            </div>
          </div>
          <input :type="field.input_type" :name="field.input_name" :id="field.input_name"
            :required="field.is_mandatory == 1 ? true : false"
            v-model="field['model_value']"
            v-if="['text', 'checkbox'].includes(field.input_type)" :placeholder="field.description" :class="
              field.input_type === 'checkbox'
                ? 'border border-neutral-300 w-full h-10 px-3 rounded-md'
                : 'appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
            " />

          <div v-if="field.input_type === 'switch'" class="flex w-full justify-between py-6">
            <div class="font-semibold" :class="field['model_value'] ? 'text-lime-600' : 'text-rose-600'">
              {{ field.label }}
            </div>
            <div>
              <SwitchBoxs v-model:value="field['model_value']"></SwitchBoxs>
            </div>
          </div>

          <textarea :type="field.input_type" :name="field.input_name" :id="field.input_name"
            v-if="field.input_type === 'textarea'" :placeholder="field.description"
            :required="field.is_mandatory == 1 ? true : false"
            v-model="field['model_value']"
            class="appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"></textarea>

          <select v-if="field.input_type === 'select'" :name="field.input_name" :id="field.input_name"
          :required="field.is_mandatory == 1 ? true : false"
            v-model="field['model_value']"
            class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example">
            <option value="">Select an option</option>
            <option v-for="(name, key) in field.options.split(',')" :key="key" :value="name">
              {{ name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-8" v-if="courserun != null">
        <div class="w-1/2 py-4 space-x-3" v-for="field in courserun.custom_fields_global" :key="field"
          style="display: inline-table">
          <div class="w-full">
            <div class="mx-2">
              <label for="name" v-html="field.label" class="block"></label>

              <input
                :type="field.input_type"
                :name="field.input_name"
                :id="field.input_name"
                v-model="field['model_value']"
                v-if="['text', 'checkbox'].includes(field.input_type)"
                :placeholder="field.description"
                :class="
                  field.input_type === 'checkbox'
                    ? 'border border-neutral-300 w-full h-10 px-3 rounded-md'
                    : 'block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                "
                :required="field.is_mandatory == 1 ? true : false"
              />

              <textarea :type="field.input_type" :name="field.input_name" :id="field.input_name"
                v-if="field.input_type === 'textarea'" :placeholder="field.description"
                v-model="field['model_global_value']"
                class="appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"></textarea>

              <select v-if="field.input_type === 'select'" :name="field.input_name" :id="field.input_name"
                v-model="field['model_global_value']"
                class="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white/75 bg-clip-padding bg-no-repeat border rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example">
                <option value="">Select an option</option>
                <option v-for="(name, key) in field.options.split(',')" :key="key" :value="name">
                  {{ name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="pb-3 text-center text-sky-600 text-lg border-b border-dashed border-sky-600">
          {{ sponsorTitle }}
        </div>

        <!--<div class="p-4 class flex justify-center mb-10">
          <input placeholder="Referral Code" v-model="refCode" type="text" class="border border-sky-300 rounded-md p-2" />
        </div>-->

        <div class="py-2 flex justify-center">
          <FlatButton @click="doRegister">
            <div class="text-xl px-10 py-2 flex items-center">
              <div class="w-8 h-8 bg-white p-1 rounded-full text-sky-500 flex items-center justify-center">
                <Vue3Lottie v-if="isLoading" :animationData="Loading" class="flex" />
                <fa-icon v-else icon="arrow-right"></fa-icon>
              </div>
              <p class="px-4 text-xl">Sign Up</p>
            </div>
          </FlatButton>
        </div>

        <div class="text-rose-500 py-4 flex text-lg justify-center" v-if="Error">
          <fa-icon icon="triangle-exclamation"></fa-icon>
          <p class="px-2">{{ Error }}</p>
        </div>
      </div>
    </div>
  </slideout>
</template>

<script>
// disable eslint for this file

import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";
import Loading from "@/assets/lotties/hourglass_loading.json";
import FlatButton from "@/components/FlatButton.vue";
import SwitchBoxs from "@/components/SwitchBoxs.vue";
import CardListTile from "@/components/CardListTile.vue";
import { mapActions, mapGetters } from "vuex";

import { courseEnroll } from "@/data/courses.data";
export default {
  props: {
    courserun: Object,
    sponsorType: null,
    showOn: {
      type: Boolean,
      default: false,
    },
    course_slug: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      Loading,
      currentStep: 0,
      Error: null,
      isLoading: false,
      refCode: null,
      form: {
        //refferal_code: null,        
        courserun: null,
        sponsorship_type: null,
        custom_fields: {},
        custom_field_global:{}
      },
    };
  },
  computed: {
    ...mapActions(["logoutAction"]),
    ...mapGetters(['getReferralCode', 'getReferralCourseId']),
    onShow: {
      get() {
        return this.showOn;
      },
      set(v) {
        return this.$emit('update:showOn', v)
      }
    },
    sponsorTitle() {
      if (this.sponsorType) {
        return this.sponsorType == "EMPLOYER"
          ? "REGISTER WITH EMPLOYER SPONSORSHIP"
          : "INDIVIDUAL REGISTRATION";
      }
      return "";
    },
  },
  watch: {
    sponsorType: function (x) {
      if (x) {
        this.form.sponsorship_type = this.sponsorType;
      }
    },
    courserun: function (x) {
      this.form.courserun = x.id;
    },
    form: {
      handler(x, v) {
        console.log(x)
        console.log(v)
        this.Error = null;
      },
      deep: true,
    },
  },
  methods: {
    async fetchRefCode() { },
    async doRegister() {
      if (!this.validateForm()) {
        return (this.Error = "Please Fill In All registration Data");
      } else {
        this.isLoading = true;
        let errorList = [];
        var regform = Object.assign({}, this.form);
        this.courserun.custom_fields.map(
          (field) => {
            if ((field.model_value == null || field.model_value == "") && field.is_mandatory == 1) {
                    errorList.push({
                        key: field.id,
                        message: field.label + " Required, Please provide " + field.label
                    })
                }
                regform.custom_fields[field.input_name] = field.model_value ? field.model_value : null
          }
        );
        if(errorList.length > 0){
            // let messages = errorList.map(r => r.message).join(",");
            let messages = "Please fill up all mandatory fields marked with *";
            this.onShow = false;
            return this.notifyError(messages);
        }
        this.courserun.custom_fields_global.map(
          (field) => {
              regform.custom_fields_global[field.input_name] = field.model_value ? field.model_value : null
          }
        );
        // regform.custom_fields = this.customfieldsJson();
        regform.sponsorship_type = this.sponsorType;
        var enrollmentPath = `try-enroll/${this.courserun.id}`; //route("cart.tryregister", { cr: this.courserun.id });
        
        // if (this.refCode) {
        //   enrollmentPath = `try-enroll/${this.courserun.id}?refCode=${this.refCode}`;
        // }

        if (!!this.course_slug && !!this.getReferralCode && this.course_slug === this.getReferralCourseId) {
          enrollmentPath = `try-enroll/${this.courserun.id}?refCode=${this.getReferralCode}`;
        }

        console.log("form", regform)
        try {
          const data = await courseEnroll(enrollmentPath, regform);
          this.notifySuccess(data);
        } catch (error) {
          
          
          if(error.response.data.message == 'Unauthenticated.' || error.response.data.message == 'Unauthenticated'){
            this.notifyError("Please Relogin ! ");
            this.onShow = false;
            return false;
          }

          let errorResponse = null;
          if(error.response.data.message){
            errorResponse = error.response.data.message;
          }
          
          if(!errorResponse){
            if(error.response.data){
              errorResponse = error.response.data;
            }
          }

          this.notifyError(errorResponse)
        }
      
        this.onShow = false;
        

        //==============================================================
        /* regform.post(enrollmentPath, {
          preserveScroll: true,
          onSuccess: () => {
            this.onClosing();
            this.Error = null;
          },
        }); */
      }
    },
    validateCustomField(){
        let errorList = [];
        var regform = Object.assign({}, this.form);
        this.courserun.custom_fields.map(
            (field) => {
                if ((field.model_value == null || field.model_value == "") && field.is_mandatory == 1) {
                    errorList.push({
                        key: field.id,
                        message: field.input_name + " Required, Please provide " + field.input_name
                    })
                }else{
                    regform.custom_fields[field.input_name] = field.model_value ? field.model_value : null
                }
            }
        );
        if(errorList.length > 0){
            let messages = errorList.map(r => r.message).join(",");
            this.showError(messages);
            return false;
        }
        return true;
    },
    validateForm() {
      return true;

/*       for (let i = 0; i < this.courserun.custom_fields.length; i++) {
        let input_type = document.getElementById(
          this.courserun.custom_fields[i].input_name
        ).type,
          input_value = this.getById(
            this.courserun.custom_fields[i].input_name,
            input_type
          );
        if (this.courserun.custom_fields[i].is_mandatory) {
          if (typeof input_value !== "boolean" && input_value === "") {
            return false;
          } else {
            return true;
          }
        }
      } */
    },

    step(prev = false) {
      if (prev) {
        return this.currentStep--;
      }
      return this.currentStep++;
    },
    onClosing(e) {
      console.log(e)
      this.form = {
        courserun: null,
        sponsorship_type: null,
        custom_fields: {},
      };
      this.isLoading = false;
      this.$emit("update:showOn", false);
    },
    customfieldsJson() {
      /*       let customFields = {};
      for (let i = 0; i < this.courserun.custom_fields.length; i++) {
        let input_type = document.getElementById(
          this.courserun.custom_fields[i].input_name
        ).type;
        customFields[this.courserun.custom_fields[i].input_name] = this.getById(
          this.courserun.custom_fields[i].input_name,
          input_type
        );
      } */
      return JSON.stringify(this.courserun.custom_fields);
    },
    getById(att, type = "") {
      if (document.getElementById(att)) {
        if (["checkbox", "radio"].includes(type)) {
          return document.getElementById(att).checked;
        } else {
          return document.getElementById(att).value;
        }
      }
    },
  },
  components: { FlatButton, Vue3Lottie, CardListTile, SwitchBoxs },
};
</script>
