const state = {
    referral_code: null,
    referral_course_id: null,
}

const getters = {
    getReferralCode(state) {
        return state.referral_code;
    },
    getReferralCourseId(state) {
        return state.referral_course_id;
    }
}

const mutations = {
    SET_REFERRAL_CODE(state, { referral_code }) {
        state.referral_code = referral_code;
    },
    SET_REFERRAL_COURSE_ID(state, { referral_course_id }) {
        state.referral_course_id = referral_course_id;
    }
}

const actions = {
    
}

export default {
    state,
    getters,
    mutations,
    actions
}