<template>
    <div class="py-1 px-3 rounded-full m-[2px]" :class="getStyles">
        <slot></slot>    
    </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
    props:{
        textStyle:{
            type:String,
            default:'text-xs font-light'
        },
        colors:{
            type:String,
            default:'bg-sky-400 text-white'
        }
    },
    computed:{
        getStyles(){
            return `${this.textStyle} ${this.colors}`;
        }
    }
})
</script>
<style lang="">
    
</style>