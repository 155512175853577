<template>  
      <router-view />
</template>
<script>
  import { defineComponent } from "vue";
 export default defineComponent({
    created(){
      const refs = this.$route.query.refs;
      const cId= this.$route.query.crs;
      if(refs){
        //fetch and store referrer data in vuex
      }
      if(cId){
        //redirect to course detail page
      }
    }
 })
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
