<!-- @format -->

<template>
  <div :class="padding">
    <div class="text-xs">
      <fa-icon class="text-neutral-400" :icon="icon"></fa-icon>
      <span class="pl-1 text-neutral-500">{{ title }}</span>
    </div>
    <div class="border-t text-indigo-900 border-dashed border-gray-200">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    padding: {
      type: String,
      default: "px-4 py-2",
    },
    icon: {
      type: String,
      default: "envelope",
    },
    title: {
      type: String,
      default: "Email",
    },
  },
});
</script>
<style lang=""></style>
